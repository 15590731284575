:root {
  --dsg-border-color: #e8ebed;
  --dsg-selection-border-color: rgb(69, 128, 230);
  --dsg-selection-border-radius: 2px;
  --dsg-selection-border-width: 2px;
  --dsg-selection-background-color: rgba(69, 128, 230, 0.04);
  --dsg-selection-disabled-border-color: #9da6ab;
  --dsg-selection-disabled-background-color: rgba(0, 0, 0, 0.04);
  --dsg-corner-indicator-width: 10px;
  --dsg-header-text-color: rgb(157, 166, 171);
  --dsg-header-active-text-color: black;
  --dsg-cell-background-color: white;
  --dsg-cell-disabled-background-color: rgb(250, 250, 250);
  --dsg-transition-duration: 0.1s;
  --dsg-expand-rows-indicator-width: 10px;
  --dsg-scroll-shadow-width: 7px;
  --dsg-scroll-shadow-color: rgba(0, 0, 0, 0.2);
}

.dsg-container {
  border: solid 1px var(--dsg-border-color);
  border-top: none;
  border-left: none;
  cursor: cell;
  user-select: none;
  box-sizing: border-box;
  color: black;
  background: white;
  position: relative;
  width: 100%;
  overflow: auto;
  will-change: transform;
  direction: ltr;
}

.dsg-corner-indicator {
  margin: auto;
  width: 0;
  border-bottom: solid var(--dsg-corner-indicator-width)
    var(--dsg-header-text-color);
  border-left: solid var(--dsg-corner-indicator-width) transparent;
}

.dsg-row {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--dsg-cell-background-color);
  box-shadow: 0 1px var(--dsg-border-color) inset;
}

.dsg-row-header {
  position: sticky;
  top: 0;
  z-index: 40;
}

.dsg-cell {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: var(--dsg-cell-background-color);
  border: solid 1px var(--dsg-border-color);
  border-bottom: none;
  border-right: none;
  box-shadow: 1px 1px var(--dsg-border-color);
  position: absolute;
  top: 0;
  height: 100%;
}

.dsg-hidden-cell {
  display: none;
}

.dsg-cell-sticky-right {
  position: sticky;
  right: 0;
  z-index: 30;
  margin-left: auto;
  transform: translateY(-100%);
}

.dsg-cell-disabled {
  background: var(--dsg-cell-disabled-background-color);
}

.dsg-cell-header,
.dsg-cell-gutter {
  color: var(--dsg-header-text-color);
  transition: color var(--dsg-transition-duration);
  box-shadow: none;
}

.dsg-cell-header-container {
  padding: 0 10px;
  line-height: 15px;
}

.dsg-cell-header.dsg-cell-gutter {
  box-shadow: 1px 0 var(--dsg-border-color), 0 1px var(--dsg-border-color);
}

.dsg-cell-header.dsg-cell-sticky-right {
  box-shadow: 0 1px var(--dsg-border-color);
}

.dsg-cell-gutter {
  position: sticky;
  left: 0;
  padding: 0 5px;
  justify-content: center;
  z-index: 30;
  height: 100%;
}

.dsg-cell-header-active,
.dsg-cell-gutter-active {
  color: var(--dsg-header-active-text-color);
}

.dsg-active-cell,
.dsg-selection-rect {
  position: absolute;
  border: solid var(--dsg-selection-border-width)
    var(--dsg-selection-border-color);
  box-sizing: border-box;
  border-radius: var(--dsg-selection-border-radius);
  transition: all var(--dsg-transition-duration);
  pointer-events: none;
  z-index: 20;
}

.dsg-active-cell-focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.dsg-active-cell-disabled,
.dsg-selection-rect-disabled {
  border-color: var(--dsg-selection-disabled-border-color);
}

.dsg-selection-rect {
  background: var(--dsg-selection-background-color);
}

.dsg-selection-rect-disabled {
  background: var(--dsg-selection-disabled-background-color);
}

.dsg-scrollable-view-container {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
}

.dsg-scrollable-view {
  position: sticky;
  z-index: 10;
  box-sizing: border-box;
  border: solid 1px var(--dsg-border-color);
  border-bottom: none;
  border-right: none;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-t {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-r {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-b {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-l {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-t.dsg-scrollable-view-r {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-r.dsg-scrollable-view-b {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-b.dsg-scrollable-view-l {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-l.dsg-scrollable-view-t {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-t.dsg-scrollable-view-b {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-l.dsg-scrollable-view-r {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-t.dsg-scrollable-view-r.dsg-scrollable-view-b {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-r.dsg-scrollable-view-b.dsg-scrollable-view-l {
  box-shadow: 0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-b.dsg-scrollable-view-l.dsg-scrollable-view-t {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-l.dsg-scrollable-view-t.dsg-scrollable-view-r {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-scrollable-view-t.dsg-scrollable-view-r.dsg-scrollable-view-b.dsg-scrollable-view-l {
  box-shadow: 0 var(--dsg-scroll-shadow-width) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    calc(0px - var(--dsg-scroll-shadow-width)) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    0 calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset,
    var(--dsg-scroll-shadow-width) 0 var(--dsg-scroll-shadow-width)
      calc(0px - var(--dsg-scroll-shadow-width)) var(--dsg-scroll-shadow-color)
      inset;
}

.dsg-selection-col-marker-container {
  position: absolute;
  top: 0;
}

.dsg-selection-col-marker {
  background: var(--dsg-selection-border-color);
  position: sticky;
  width: 100%;
  height: var(--dsg-selection-border-width);
}

.dsg-selection-row-marker-container {
  position: absolute;
  left: 0;
}

.dsg-selection-row-marker {
  background: var(--dsg-selection-border-color);
  position: sticky;
  height: 100%;
  width: var(--dsg-selection-border-width);
}

.dsg-selection-col-marker-container,
.dsg-selection-col-marker,
.dsg-selection-row-marker-container,
.dsg-selection-row-marker {
  z-index: 20;
  transition: all var(--dsg-transition-duration);
  pointer-events: none;
}

.dsg-selection-col-marker-disabled,
.dsg-selection-row-marker-disabled {
  background: var(--dsg-selection-disabled-border-color);
}

.dsg-add-row {
  background: #fafafa;
  border: 1px solid var(--dsg-border-color);
  border-top: none;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  color: black;
}

.dsg-add-row > * {
  margin-right: 10px;
}

.dsg-add-row-btn,
.dsg-add-row-input {
  background: white;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  transition: all 0.2s;
}

.dsg-add-row-input {
  width: 55px;
}

.dsg-add-row-btn:hover,
.dsg-add-row-input:hover {
  border-color: #c7c7c7;
}

.dsg-add-row-btn:focus,
.dsg-add-row-input:focus {
  border-color: var(--dsg-selection-border-color);
  outline: none;
}

.dsg-add-row-btn:before {
  content: '';
  display: inline-block;
  margin-right: 10px;
  background: #9da6ab;
  width: 10px;
  height: 2px;
}

.dsg-add-row-btn:after {
  content: '';
  display: block;
  position: absolute;
  left: 14px;
  margin-right: 10px;
  background: #9da6ab;
  width: 2px;
  height: 10px;
}

.dsg-input {
  display: block;
  flex: 1;
  border: none;
  min-width: 0;
  align-self: stretch;
  box-sizing: border-box;
  background: none;
  padding: 0 10px;
  font-size: 1rem;
  outline: none;
}

.dsg-input-suffix {
  padding-right: 10px;
  opacity: 0.5;
  margin-left: -5px;
}

.dsg-input-align-right {
  text-align: right;
}

.dsg-hide-date-picker[type="date"]::-webkit-inner-spin-button,
.dsg-hide-date-picker[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.dsg-checkbox {
  margin: auto;
}

.dsg-context-menu {
  position: fixed;
  background: white;
  z-index: 10000;
  border-radius: 4px;
  box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  padding: 5px 0;
  color: black;
}

.dsg-context-menu-item {
  padding: 3px 20px;
  cursor: default;
}

.dsg-context-menu-item:hover {
  background: #fafafa;
}

.dsg-expand-rows-indicator {
  background: var(--dsg-cell-background-color);
  border: solid 1px var(--dsg-selection-border-color);
  box-shadow: 0 0 0 1px var(--dsg-cell-background-color);
  width: var(--dsg-expand-rows-indicator-width);
  height: var(--dsg-expand-rows-indicator-width);
  box-sizing: border-box;
  position: absolute;
  transition: all var(--dsg-transition-duration);
  cursor: crosshair;
  z-index: 25;
}

.dsg-expand-rows-indicator-disabled {
  border: solid 1px var(--dsg-selection-disabled-border-color);
}

.dsg-expand-rows-rect {
  position: absolute;
  box-sizing: border-box;
  transition: all var(--dsg-transition-duration);
  pointer-events: none;
  background: rgba(0, 0, 0, 0.03);
}
